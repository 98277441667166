.color-item{
    background-color: rgba(65, 65, 66, 0.53);
    margin: 10px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 18px;
}

.color-title {
    color: #A1A1A1;
    margin-bottom: 10px;
    font-weight: bold;
}